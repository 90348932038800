import React from 'react'
import PropTypes from 'prop-types'
import Link from 'next/link'
// import { useRouter } from 'next/router'

const CustomLink = ({ href, children, ...rest }) => {
  // const router = useRouter()
  // const path = router?.asPath
  // const [, queryString] = path?.split('?')
  return (
    <Link href={href || ''} {...rest}>{children}</Link>
  )
}

CustomLink.propTypes = {
  href: PropTypes.string.isRequired,
  children: PropTypes.any.isRequired
}

export default CustomLink
